import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react"
import React from "react"
import { History as HistoryType } from "../../types"
import { Confirm } from "../Confirm"
import { ErrorBoundary } from "../ErrorBoundary"
import { History } from "../typing-test-2/History"
import Stats from "./Stats"

export function RecordingMenu({
  recording,
  onDelete,
}: {
  recording: HistoryType
  onDelete: () => void
}) {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <Menu placement="bottom-end">
        <MenuButton
          as={IconButton}
          icon={<b>︙</b>}
          rounded="full"
          size="sm"
          variant="ghost"
        />
        <MenuList>
          <MenuItem onClick={onOpen}>View Replay</MenuItem>
          <MenuDivider />
          <Confirm
            onConfirm={onDelete}
            title="Are you sure you want to delete?"
          >
            {openDialog => (
              <MenuItem fontWeight="bold" onClick={openDialog}>
                Delete
              </MenuItem>
            )}
          </Confirm>
        </MenuList>
      </Menu>
      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Replay from {new Date(recording.endTimestamp).toLocaleString()}
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody>
            <ErrorBoundary showReset>
              <Stats
                durationSeconds={recording.durationSeconds}
                wordsToType={recording.wordsToType}
                typedWords={recording.typedWords}
              />
              <Box height={8} />
              <History history={recording} />
            </ErrorBoundary>
          </ModalBody>

          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
