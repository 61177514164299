import {
  Box,
  Center,
  Divider,
  HStack,
  SlideFade,
  Spacer,
  Text,
  Tooltip,
  useColorModeValue,
  VStack,
} from "@chakra-ui/react"
import formatRelative from "date-fns/formatRelative"
import React, { FC, useEffect, useState } from "react"
import { useAuthenticated } from "../auth/Authenticated"

type Stat = {
  timestamp: Date
  wpm: number
}

const Result: FC<Stat> = ({ timestamp, wpm }) => {
  const color = useColorModeValue("gray.200", "gray.700")
  const hoverColor = useColorModeValue("gray.900", "gray.100")
  return (
    <Tooltip label={formatRelative(timestamp, new Date())} closeDelay={0}>
      <Box
        color={color}
        _hover={{ color: hoverColor }}
        display="flex"
        flexDirection="column"
        flexGrow={1}
      >
        <Spacer />
        {/* Make these bars tall enough that 250 wpm takes up the entire vertical screen real estate on my macbook pro. I want the stat to look so good that it appears the website isn't supposed to handle it */}
        <Box w="100%" bg="currentColor" height={`${wpm * 5}px`} rounded="md" />
        <Text fontWeight="bold">{wpm}</Text>
      </Box>
    </Tooltip>
  )
}

/** Array of stats */
function useLatestStatsList() {
  const { app, user } = useAuthenticated()
  const [latestStatsList, setLatestStatsList] = useState<Stat[]>([])

  useEffect(() => {
    console.log("get latest")
    const unsubscribe = app
      .firestore()
      .collection("users")
      .doc(user.uid)
      .collection("stats")
      .orderBy("timestamp")
      .limitToLast(15)
      .onSnapshot(querySnapshot => {
        const stats: Stat[] = []
        querySnapshot.forEach(stat => {
          const { wpm, timestamp } = stat.data()
          stats.push({
            wpm,
            timestamp: new Date(timestamp.seconds * 1000),
          })
        })
        setLatestStatsList(stats)
      })
    return unsubscribe
  }, [app, user])

  return latestStatsList
}

export function SavedStats() {
  const statsList = useLatestStatsList()

  if (statsList.length === 0) return null
  if (statsList.length < 2) return null

  return (
    <SlideFade in>
      <Center flexDir="column">
        <VStack spacing={2} width="100%">
          <HStack justifyContent="center" alignItems="stretch" width="100%">
            {statsList.map(result => (
              <Result
                key={result.timestamp.toString()}
                timestamp={result.timestamp}
                wpm={result.wpm}
              />
            ))}
          </HStack>
          <Divider />
          <Text fontWeight="bold">WPM</Text>
        </VStack>
      </Center>
    </SlideFade>
  )
}
