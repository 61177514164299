import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  SlideFade,
  Tooltip,
  VStack,
} from "@chakra-ui/react"
import { take } from "lodash-es"
import React from "react"
import Statistics from "statistics.js"
import { Authenticated2 } from "../../components/auth/Authenticated2"
import { BigMessageSpinner } from "../../components/BigMessageSpinner"
import { Kv, Kvs } from "../../components/Kvs"
import { BodyWrapper } from "../../components/layout/BodyWrapper"
import { Layout } from "../../components/layout/Layout"
import { Nav } from "../../components/layout/Nav"
import { PageHeading } from "../../components/layout/PageHeading"
import { PageTitle } from "../../components/typing-test/PageTitle"
import { RecordingsTable } from "../../components/typing-test/RecordingsTable"
import { SavedStats } from "../../components/typing-test/SavedStats"
import { History } from "../../types"
import { useLastRecordings } from "../../useLastRecordings"
import { stats } from "../../wordUtils"

function totalTimeMinutes(recordings: History[]) {
  return Math.round(
    recordings.reduce((acc, curr) => acc + curr.durationSeconds, 0) / 60
  )
}

function correlateAccuracyAndSpeed(stats: object[]) {
  if (stats.length === 0) return 0

  const bodyVars = {
    wpm: "metric",
    wordAccuracy: "metric",
  }

  const statistics = new Statistics(stats, bodyVars)
  const r = statistics.correlationCoefficient("wpm", "wordAccuracy")
  return r.correlationCoefficient
}

function StatsTable() {
  const { isLoading, recordings, deleteRecording } = useLastRecordings(200)

  if (isLoading || !recordings) {
    return <BigMessageSpinner message="Loading stats..." />
  }

  if (recordings.length === 0) {
    return (
      <SlideFade in>
        <Alert>
          <AlertIcon />
          <Box flex="1">
            <AlertTitle>No Recordings</AlertTitle>
            <AlertDescription display="block">
              Do some typing tests and come back here to see your stats.
            </AlertDescription>
          </Box>
        </Alert>
      </SlideFade>
    )
  }

  const statsArr = recordings.map(rec => {
    const { wpm, wordAccuracy } = stats({
      durationSeconds: rec.durationSeconds,
      wantedWords: rec.wordsToType,
      actualWords: rec.typedWords,
    })
    return { wpm, wordAccuracy }
  })

  return (
    <SlideFade in>
      <VStack spacing={10}>
        <div style={{ alignSelf: "start" }}>
          <Kvs>
            <Kv k="Finished runs" v={recordings.length} />
            {/* From finished runs */}
            {statsArr.length >= 10 && (
              <Kv
                k="Last 10 runs WPM"
                v={Math.round(
                  take(statsArr, 10).reduce((acc, curr) => acc + curr.wpm, 0) /
                    10
                )}
              />
            )}
            <Kv
              k="Average WPM"
              v={Math.round(
                statsArr.reduce((acc, curr) => acc + curr.wpm, 0) /
                  statsArr.length
              )}
            />
            <Kv k="Fastest WPM" v={Math.max(...statsArr.map(s => s.wpm))} />
            <Kv
              k="Average word accuracy"
              v={
                (
                  (statsArr.reduce((acc, curr) => acc + curr.wordAccuracy, 0) /
                    statsArr.length) *
                  100
                ).toFixed(1) + "%"
              }
            />
            <Kv
              k="Last 10 runs word accuracy"
              v={
                (
                  (take(statsArr, 10).reduce(
                    (acc, curr) => acc + curr.wordAccuracy,
                    0
                  ) /
                    10) *
                  100
                ).toFixed(1) + "%"
              }
            />
            <Kv
              k={<Tooltip label="From finished runs">Total time</Tooltip>}
              v={`${totalTimeMinutes(recordings)} minutes`}
            />
            <Kv
              k={
                <Tooltip label="Usually, typing faster means less mistakes. Higher correlation means typing more accurately helps your speed.">
                  Accuracy and speed
                </Tooltip>
              }
              v={
                (correlateAccuracyAndSpeed(statsArr) * 100).toPrecision(2) +
                "% correlation"
              }
            />
          </Kvs>
        </div>
        <RecordingsTable recordings={recordings} onDelete={deleteRecording} />
      </VStack>
    </SlideFade>
  )
}

export default function StatsPage() {
  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
      <PageTitle>📊 Stats</PageTitle>
      <Layout>
        <Nav showBottomBorder />
        <BodyWrapper>
          {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
          <PageHeading>📊 Stats</PageHeading>
          <Authenticated2>
            <SavedStats />
            <StatsTable />
          </Authenticated2>
        </BodyWrapper>
      </Layout>
    </>
  )
}
