import { Tooltip } from "@chakra-ui/react"
import { formatDistance } from "date-fns"
import React, { useMemo } from "react"
import { History } from "../../types"
import { stats, wpm } from "../../wordUtils"
import { Table } from "../Table"
import { RecordingMenu } from "./RecordingMenu"

export function RecordingsTable({
  recordings,
  onDelete,
}: {
  recordings: History[]
  onDelete: (firebaseDocId: string) => void
}) {
  const data = useMemo(
    () =>
      recordings.map(rec => {
        const theStats = stats({
          durationSeconds: rec.durationSeconds,
          wantedWords: rec.wordsToType,
          actualWords: rec.typedWords,
        })
        const potentialWpm = wpm(rec.typedWords.length, rec.durationSeconds)
        return {
          ts: rec.endTimestamp,
          catalogItemId: rec.catalogItemId,
          wpm: theStats.wpm,
          potentialWpm,
          accuracy: Math.round(theStats.wordAccuracy * 100) + "%",
          menuData: {
            recording: rec,
            firebaseDocId: rec.id,
          },
        }
      }),
    [recordings]
  )

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        Cell: ({ value }: { value: any }) => (
          <Tooltip label={new Date(value).toLocaleString()}>
            {formatDistance(value, new Date(), { addSuffix: true })}
          </Tooltip>
        ),
        accessor: "ts",
      },
      // {
      //   Header: "Run type",
      //   accessor: "catalogItemId",
      // },
      {
        Header: "WPM",
        accessor: "wpm",
        isNumeric: true,
      },
      {
        Header: "Potential WPM",
        accessor: "potentialWpm",
        isNumeric: true,
      },
      {
        Header: "Accuracy",
        accessor: "accuracy",
        isNumeric: true,
      },
      {
        Header: "",
        accessor: "menuData",
        isNumeric: true,
        Cell: ({
          value,
        }: {
          value: { recording: History; firebaseDocId: string }
        }) => (
          <RecordingMenu
            recording={value.recording}
            onDelete={() => onDelete(value.firebaseDocId)}
          />
        ),
      },
    ],
    [onDelete]
  )

  return <Table columns={columns} data={data} />
}
